 const detaildata = [
    {
      'id': '1',
      'image': require("./image-spring.jpg"),
      'year': 2012,
      'title': 'Вебинар о геологоразведке',
      'summary': 'О секретах одной из самых важных профессий добывающей промышленности сотрудники «Полиметалла» могут узнать на специальном вебинаре, который проведет ассоциация «Women in Mining Russia» О секретах одной из самых важных профессий добывающей промышленности сотрудники «Полиметалла» могут узнать на специальном вебинаре, который проведет ассоциация «Women in Mining Russia»О секретах одной из самых важных профессий добывающей промышленности сотрудники «Полиметалла» могут узнать на специальном вебинаре, который проведет ассоциация «Women in Mining Russia» ',
      'genres': 'Подробнее',
    },
    {
      'id': '2',
      'image': require("./img-bg.jpg"),
      'year': 2015,
      'title': 'Вебинар о геологоразведке, Вебинар о',
      'summary': 'О секретах одной из самых важных профессий добывающей промышленности сотрудники «Полиметалла» могут',
      'genres': 'Подробнее',
    },
  ];
  export default detaildata;
