import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import './style.css';

//Обернем весь контент компонента в ссылку(при клике она перекидывает на страницу "/movie-details"), которая передает все параметры этого компонента - {year, title, summary, poster, genres}

export default function MovieComponent({id, year, title, summary, poster, genres}) {
    return (
        <div className="movie" key={id}>
            
                <img src={poster} alt={title} title={title} />
                <div className="movie_desc">
                    <h3 className="movie_title">{title}</h3>
                    <h5 className="movie_year">{year}</h5>
                    <p className="movie_summary">{summary.slice(0, 150)}...</p>
                    <ul className="genres_list">
                        {genres.map((genre, index) => {
                            return (
                                <li key={index} className="genres_list-item">{genre}</li>
                            );
                        })}
                    </ul>
                </div>
            
        </div>
    );
}

MovieComponent.propTypes = {
    id: PropTypes.number.isRequired,  //цифры
    year: PropTypes.number.isRequired,  //строки
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    poster: PropTypes.string.isRequired,
    genres: PropTypes.arrayOf(PropTypes.string).isRequired //массив строк списка
};

//<p className="movie_summary">{summary.slice(0, 150)}...</p> - ограничение количества символов

{/*Link
                to={{
                    pathname: "/movie-details/:id",
                    state: { from: id, 
                        year: year,
                        title: title,
                        summary: summary,
                        poster: poster,
                        genres: genres,
                    },
                }}
            ></Link>*/}