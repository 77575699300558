import { useParams } from "react-router-dom";
import Navigation from "../Navigation";

const Post = () => {
    const {id, year} = useParams();
    return (        
        <div>
            <Navigation/>
            <h5>{year}</h5>
            <img src={id} alt="image" />
        </div>
    )
}

export default Post;


//