const selectComponentdata = [
    {
      'id': '1',
      'image': require("./image-spring.jpg"),
    },
    {
        'id': '2',
        'image': require("./img-bg.jpg"),
      },
  ];
  export default selectComponentdata;