import React from 'react';
import PropTypes from "prop-types";
import { motion } from "framer-motion"
import './style.css';


const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
    big: {scale:2},
    normal:{scale:1},
    small:{scale:0.1},
    rotate:{rotate:180},
    circle:{borderRadius:"100%"}

  }

  /**
 * @param {String} image
 */

export default function SelectComponent({selectedMode, image}) {

    return (
    <div className='home'>
        <motion.img
            initial={"open"}
            animate={selectedMode}
            variants={variants}
            transition={{ duration: 0.5 }}
            className='shape' src={image}
        />           
    </div>)
}

SelectComponent.propTypes = {
    id: PropTypes.string.isRequired, 
    image: PropTypes.string.isRequired,
};

//<img src={image} alt='image' />