import React from "react";
import { useState } from "react";
import "./style.css";
//import sendpostcardbtn from "./assets/image-btn.png";
//import sendpostcardbg from "./assets/image-spring.jpg";
import backgroundbg from "./assets/img-bg.jpg";

import dataButtonSpringElement from "../../assets/_template/springbtndata";
import dataIageSpringElement from "../../assets/_template/springimgdata";
import dataIageTextSpringElement from "../../assets/_template/springimgtextdata";

function ImageClickComponent() {
    const [isVisible, setIsVisible] = useState(false);
    
  return (
    <div className="spring-element_wrap">
      {dataButtonSpringElement.map((el, id) => (
        <div key={id}>
          <div className="button-spring-element__list">
            <div className="button-spring-element" onClick={() => {
              setIsVisible(v => !v);
            }} >
              <img className="button-spring-element__image" src={el.image} alt="horse" />
            </div>
          </div>
        </div>
      ))}
      <div className="container-image" style={{ backgroundImage: `url(${backgroundbg})` }}>
        {dataIageSpringElement.map((el, id) => (
          <div key={id}>
            {isVisible ?
              <div className="item-img__wrap">
                <img className="item-img" src={el.image} alt="horse" />
              </div>
              : ''}
          </div>
        ))}
        {dataIageTextSpringElement.map((el, id) => (
          <div key={id}>
            {isVisible ?
              <div className="items-img__wrap">
                <img className="items-img" src={el.image} alt="horse" />
              </div>
              : ''}
          </div>
        ))}
      </div>      
    </div>
  );
}

export default ImageClickComponent;

//function ImageClickComponent() {
//  const [isVisible, setIsVisible] = useState(false);
  
//return (
//  <div className="spring-element_wrap">
//    <div className="button-spring-element" onClick={() => {
//      setIsVisible(v => !v);
//    }} style={{ backgroundImage: `url(${sendpostcardbtn})` }}></div>
//    <div className="container-image" style={{ backgroundImage: `url(${backgroundbg})` }}>
 //     {isVisible ? <div className="item-img" style={{ backgroundImage: `url(${sendpostcardbg})` }} /> : ''}
//    </div>
//  </div>
//);
//}

//export default ImageClickComponent;
