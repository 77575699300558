import React, { useState, useEffect } from "react";
import DetailComponent from "../../Components/DetailComponent";
import ImageClickComponent from "../../Components/ImageClickComponent";
import Navigation from "../../Components/Navigation";
import CardSportsEventsImage from "../../Components/CardSportsEventsImage";
import CardSportsEventsLink from "../../Components/CardSportsEventsLink";
import CardDescSportsEvents from "../../Components/CardDescSportsEvents";
import './style.css';

import detaildata from "../../assets/_template/detaildata";
import dataEvents from "../../assets/_template/dataEvents";

export default function About() {
  // events
  //const { data: dataEvents } = useDataEvents();

  const [hoveringEvent, setHoveringEvent] = useState(undefined);

  const handleMouseOverEvent = (event) => {
    setHoveringEvent(event);
  };

  const handleMouseOutEvent = () => {
    setHoveringEvent(undefined);
  };
  //useEffect(() => {
  //  if (dataEvents.length > 0) {
  //    handleMouseOverEvent(dataEvents[0]);
  //  }
  //}, [dataEvents]);

  return (
    <div className="about-wrap">
      <Navigation />
      <div><ImageClickComponent /></div>

      <div className="sports__events_wrap">
        <div className="sports-events_image-wrap">
          {hoveringEvent && (
            <div>
              <CardSportsEventsImage
                key={`${hoveringEvent.type}-${hoveringEvent.id}`}
                imagebg={hoveringEvent.imagebg}
              />
            </div>
          )}
        </div>
        <ul className="list_sports-events">
          {dataEvents.map((el, id) => (
            <li className="list_sports-events__item" key={id}>
              
                <div onMouseOver={() => handleMouseOverEvent(el)}>
                  <CardSportsEventsLink
                    image={el.image}                    
                    hover={hoveringEvent && hoveringEvent.id == el.id}
                  />
                </div>
              
            </li>
          ))}
        </ul>
        <div className="desc_sports-events">
          <div
            key={`event-preview-${hoveringEvent && hoveringEvent.id}`}
          >
            <CardDescSportsEvents
              content={(hoveringEvent && hoveringEvent.content) || ""}
            />
          </div>
        </div>
      </div>

      <div className="details">
        {detaildata.map((el, id) => (
          <div key={id}>
            <DetailComponent
              image={el.image}
              year={el.year}
              title={el.title}
              summary={el.summary}
              genres={el.genres}
            />
          </div>
        ))}
      </div>

    </div>
  );
}


 //<div className="details">
  //            {detaildata.map((el, id) => (
                
  //              <DetailComponent  
 //                 key={id}                
  //                image={el.image}
 //                 year={el.year}
 //                 title={el.title}
 //                 summary={el.summary}
 //                 genres={el.genres}
  //              />
                
  //            ))}
   //         </div>