import React from "react";
import axios from "axios";
//import { useLocation } from "react-router-dom";
import Navigation from "../Navigation";
import { useParams } from "react-router-dom";
//import MovieComponent from "../MovieComponent";

const DetailMovie = () => {
    const [wovie, setWovie] = React.useState();
    const {id} = useParams();

    React.useEffect(() => {
        async function fetchMovie() {
            try {
                const { data } = await axios.get('https://yts.mx/api/v2/list_movies.json?sort_by=rating/items/' + id);
                setWovie(data);
            } catch (error) {
                alert('Ошибка при получении фильма');
            }
        }
        fetchMovie();
    }, []);

    if (!wovie) {
        return 'Загрузка... films...';
    }
    
    return (
        <div>
            <Navigation />
            <span>Описание фильма</span>
            <h1>{wovie.title}</h1>            
        </div>
    );

}

export default DetailMovie;

{/*
export default function DetailMovie (props) {
    const location = useLocation();
    const data = location.data;
    return (
        <div>
            <Navigation />
            <span>Описание фильма</span>
            <h1> {data ? data.title : "Go to Home"} </h1>
        </div>
    );
}
*/}