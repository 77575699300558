import React from "react";
import PropTypes from "prop-types";
import './style.css';

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} image
 * @param {Bool} hover
 */
export default function Card({
  image,
  className,
  id,
  hover,
}) {
  return (
    <div className={("card__sports-events", className, id)}>
      <div
        className={(
          "card__sports-events_content--image",
          hover ? "card__sports-events_content--image-hover" : ""
        )}
      >
        <div className={("card__sports-events_image-wrap")}>
          <img className={("item-img")} src={image} alt="" />
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  image: PropTypes.string.isRequired,
  hover: PropTypes.bool,
};
