import React from "react";
import './style.css';
import axios from "axios";
import MovieComponent from "../../Components/MovieComponent";
import Navigation from "../../Components/Navigation";
import { Link } from "react-router-dom";

class Home extends React.Component {

  state = {
    isLoading: true,  //стейт загрузки
    movies: []        //массив загруженных фильмов   
  };

  getMovies = async () => {
    const { data: {data: {movies},},} = await axios.get('https://yts.mx/api/v2/list_movies.json?sort_by=rating/items/'); //с помощью метода axios асинхронно(отдельно запрос и отдельно ответ) создадим список фильмов, взятый по ссылке
    this.setState({movies: movies, isLoading: false}); //когда получим ответ от сервера то пишет что страница загружена
  }

  componentDidMount() {
    this.getMovies();     //само создание списка фильмов(монтирование компонента)
  }

  render() {
    const { isLoading, movies } = this.state;
    return (
      <div>
        <Navigation/>
        <section className="container">
          {isLoading ? (
            <div className="loader">
              <span className="loader_text">Загрузка...</span>
            </div>
          ) : (
            <div className="movies">
              {movies.map((movie) => (
                <Link className="movies_link" key={movie.id} to={`movie-details/${movie.id}`}>
                <MovieComponent
                  key={movie.id}
                  id={movie.id}
                  year={movie.year}
                  title={movie.title}
                  summary={movie.summary}
                  poster={movie.medium_cover_image}
                  genres={movie.genres}
                />
                </Link>
              ))}
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default Home;

