import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import './style.css';

//Обернем весь контент компонента в ссылку(при клике она перекидывает на страницу "/movie-details"), которая передает все параметры этого компонента - {year, title, summary, poster, genres}

/**
 * @param {String} id
 * @param {String} image
 * @param {String} year
 * @param {String} title
 * @param {String} summary
 * @param {String} genres
 */

export default function DetailComponent({id, image, year, title, summary, genres}) {
    return (
        <div className="detail" id>
            <Link
                to={{
                    pathname: "/details/:id",
                    state: {
                        id: id,
                        image: image,
                        year: year,
                        title: title,
                        summary: summary,
                        genres: genres,
                    },
                }}
            >
                <img src={image} alt={title} title={title} />
                <div className="detail_desc">
                    <h3 className="detail_title">{title}</h3>
                    <h5 className="detail_year">{year}</h5>
                    <p className="detail_summary">{summary.slice(0, 150)}...</p>
                    <ul className="genres_list">
                        <li className="genres_list-item">{genres}</li>
                    </ul>
                </div>
            </Link>
        </div>
    );
}

DetailComponent.propTypes = {
    id: PropTypes.string.isRequired, 
    image: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,  //строки
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    genres: PropTypes.string.isRequired,
    //genres: PropTypes.arrayOf(PropTypes.string).isRequired //массив строк списка
};

//<p className="detail_summary">{summary.slice(0, 150)}...</p> - ограничение количества символов

//<li className="genres_list-item">{genres}</li>

//{genres.map((genre, index) => {
//    return (
//        <li key={index} className="genres_list-item">{genre}</li>
//    );
//})}

//<Link
 //               to={{
 //                   pathname: "/detail-details/:id",
 //                   state: { from: id, 
 //                       image: image,
 //                       year: year,
  //                      title: title,
 //                       summary: summary,
  //                      genres: genres,
 //                   },
 //               }}
  //          ></Link>
