import React, { useState } from "react";
import SidebarSelect from "../../Components/SidebarSelect";
import SelectComponent from "../../Components/SelectComponent";
import Navigation from "../../Components/Navigation";
import './style.css';

import selectComponentdata from "../../assets/_template/selectdata";

export default function About() {
  const [selectedMode, setSelectedMode] = useState('open')
  return (
      <div className="app-wrap">
          <Navigation />
          <div className='app'>
              <SidebarSelect setSelectedMode={setSelectedMode} />
              <div className="components-select">
                  {selectComponentdata.map((el, id) => (
                      <div key={id}>
                          <SelectComponent selectedMode={selectedMode}
                              image={el.image}
                         />
                      </div>
                  ))}
              </div>
          </div>
      </div>
  );
}


//<div className="components-select">
//                  {selectComponentdata.map((el, id) => (
 //                     <div key={id}>
 //                         <SelectComponent selectedMode={selectedMode}
 //                             image={el.image}
 //                         />
  //                    </div>
 //                 ))}
 //             </div>


 //<SelectComponent selectedMode={selectedMode} />