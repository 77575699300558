import React from "react";
import PropTypes from "prop-types";
import './style.css';

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} content
 */
export default function Card({ className, id, content }) {
  return (
    <div className={("card__desc_sports-events" , className, id)}>
      <div className="card__desc_sports-events_body">
        <div className="card__desc_sports-events_content">
          <p
            className="card__desc_sports-events_content-text">{content}</p>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  content: PropTypes.string.isRequired,
};
