import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Detail from "./Components/Detail";
import About from "./routes/About";
import DetailDetails from "./Components/DetailDetails";
import PageSelected from "./routes/PageSelected";
import Blogpage from "./routes/Blogpage";
import Post from "./Components/Post";
//import DetailComponent from "./Components/DetailComponent";
//import DetailMovie from "./Components/DetailMovie";

//Здесь пишем маршруты страниц и пути к ним

export default function App() {
  //const [data, setData] = useState(); 
  
    return (
        <div className="app-wrap">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/movie-details/:id" element={<Detail />} />
          <Route path="/about" element={<About />} />
          <Route path="/details/:id" element={<DetailDetails />} />
          <Route path="/pageselected" element={<PageSelected />} />
          <Route path="/posts" element={<Blogpage />} />
          <Route path="/posts/movies/:id" element={<Post />} />
        </Routes>
        </div>
    );
}

//

//
//import ClassComp from "./Components/ClassComponent";
//import SpringComponent from "./Components/SpringComponent";
//import ImageClickComponent from "./Components/ImageClickComponent";
//import MyName from "./Components/MyName";
//import dataLike from "./Components/MyName/assets/dataLike";

//
//return (
//  <div>
//    <h1>Helloy</h1> 
//    <ClassComp/>
//    {dataLike.map((food, id) => 
//      <div key={id}>
//        <MyName name={food.name} image={food.image} rating={food.rating} />
//      </div>
//    )}
//    <div className="spring-wrap">
//      <SpringComponent/>
 //   </div>
//    <div><ImageClickComponent/></div>
//  </div>
 //   );*/}
//
