import React from "react";
import { NavLink } from "react-router-dom";
import './style.css';

export default function Navigation() {
    return (
        <div className="nav-wrap">
            <nav className="nav-link">
                <NavLink className="link-nav_item" to="/">Home</NavLink>
                <NavLink className="link-nav_item" to={{
                    pathname: "/about",
                    state: {
                        fromLocation: true,
                    },
                }}
                >
                    About
                </NavLink>
                <NavLink className="link-nav_item" to="/pageselected">PageSelected</NavLink>
                <NavLink className="link-nav_item" to="/posts">Blog</NavLink>
            </nav>
        </div>
    )
}
