import React from "react";
import axios from "axios";
import Navigation from "../../Components/Navigation";
import './style.css';
//import { useState } from "react";
//import { useEffect } from "react";
import { Link } from "react-router-dom";

class Blogpage extends React.Component {
    state = {
        isLoading: true,  //стейт загрузки
        movies: []        //массив загруженных фильмов   
      };
    getMovies = async () => {
        const { data: {data: {movies},},} = await axios.get('https://yts.mx/api/v2/list_movies.json?sort_by=rating/items/'); //с помощью метода axios асинхронно(отдельно запрос и отдельно ответ) создадим список фильмов, взятый по ссылке
        this.setState({movies: movies, isLoading: false}); //когда получим ответ от сервера то пишет что страница загружена
      } 

      componentDidMount() {
        this.getMovies();     //само создание списка фильмов(монтирование компонента)
      }  

      render() {
        const { isLoading, movies } = this.state;    
  return (
    <div className="about-wrap">
      <Navigation />
      <h1>Blog-Post</h1>
      {isLoading ? (
            <div className="loader">
              <span className="loader_text">Загрузка...</span>
            </div>
          ) : (
      
      <div>
      {
        movies.map(movie => (
            <Link key={movie.id} to={`movies/${movie.id}`}>
                <h5>{movie.year}</h5>
                <img src={movie.medium_cover_image} alt="image" />
            </Link>
        ))
      }
      </div>
      )}
    </div>
  );
}

}

export default Blogpage;

{/*const Blogpage = () => {
    const [posts, setPosts] = useState({});

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/posts')
        .then(res => res.json())
        .then(data => setPosts(data))
    }, []);

  return (
    <div className="about-wrap">
      <Navigation />
      <h1>Blog-post</h1>
      {
        posts.map(post => (
            <Link key={post.id} to={`/posts/${post.id}`}>
                <li>{post.title}</li>
            </Link>
        ))
      }
    </div>
  )
}

export default Blogpage;*/}