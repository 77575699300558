const dataEvents = [
  {
    id: 1,
    image: require("./image-hover.jpg"),
    content: "Давай подавай: сборная Петербурга по волейболу",
    imagebg: require("./image-hover.jpg"),
  },
  {
    id: 2,
    image: require("./image-hover-1.jpg"),
    content: "Давай залезай: сборная Петербурга набирает новичков",
    imagebg: require("./image-hover-1.jpg"),
  },
  {
    id: 3,
    image: require("./image-hover-2.jpg"),
    content: "Давай посмотри: сборная Петербурга на вершине",
    imagebg: require("./image-hover-2.jpg"),
  },
  {
    id: 4,
    image: require("./image-hover-1.jpg"),
    content: "Давай залезай: сборная Петербурга набирает новичков",
    imagebg: require("./image-hover-1.jpg"),
  },
  {
    id: 5,
    image: require("./image-hover-2.jpg"),
    content: "Давай посмотри: сборная Петербурга на вершине",
    imagebg: require("./image-hover-2.jpg"),
  },
  {
    id: 6,
    image: require("./image-hover.jpg"),
    content: "Давай подавай: сборная Петербурга по волейболу",
    imagebg: require("./image-hover.jpg"),
  },
];
export default dataEvents;
