import React from "react";
import PropTypes from "prop-types";
import './style.css';

/**
 * 
 * @param {String|Array|Object} className
 * @param {String|Array|Object} id
 * @param {String} imagebg
 */
export default function Card({ imagebg, className, id }) {
  return (
    <div className={("card_sports-events", className, id)}>
      <div
        className={("card__sports-events_content")}
        style={{ backgroundImage: `url(${imagebg})` }}
      >
        <div className={("card__sports-events_wrap")}></div>
      </div>
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  imagebg: PropTypes.string.isRequired,
};
