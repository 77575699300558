import React from "react";
import { useParams } from "react-router-dom";
//import DetailComponent from "../DetailComponent";
//import { useLocation } from "react-router-dom";
import Navigation from "../Navigation";
//import detaildata from "../../assets/_template/detaildata";

const DetailDetails = () => {
    
    const {id} = useParams();
    
    return (
        <div>
            <Navigation />
            <span>Описание фильма</span>
            
            <h5>{id}</h5>
            
        </div>
    );

}

export default DetailDetails;